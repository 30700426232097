import React from "react";
import { Switch } from "@material-ui/core";

import "./WorkingHoursForm.css";

export default ({
  mondayOpen,
  mondayClose,
  tuesdayOpen,
  tuesdayClose,
  wednesdayOpen,
  wednesdayClose,
  thursdayOpen,
  thursdayClose,
  fridayOpen,
  fridayClose,
  saturdayOpen,
  saturdayClose,
  sundayOpen,
  sundayClose,
  onChangeWorkingHours,
  isMondayClosed,
  isTuesdayClosed,
  isWednesdayClosed,
  isThursdayClosed,
  isFridayClosed,
  isSaturdayClosed,
  isSundayClosed,
  onChangeIsClosed,
  isMobile,
}) => (
  <div className={isMobile ? "mobile-form-div" : "form-div"}>
    <text>Segunda</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isMondayClosed}
        name="mondayOpen"
        value={mondayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isMondayClosed}
        name="mondayClose"
        value={mondayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isMondayClosed}
        name="isMondayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
    <text>Terça</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isTuesdayClosed}
        name="tuesdayOpen"
        value={tuesdayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isTuesdayClosed}
        name="tuesdayClose"
        value={tuesdayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isTuesdayClosed}
        name="isTuesdayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
    <text>Quarta</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isWednesdayClosed}
        name="wednesdayOpen"
        value={wednesdayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isWednesdayClosed}
        name="wednesdayClose"
        value={wednesdayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isWednesdayClosed}
        name="isWednesdayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
    <text>Quinta</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isThursdayClosed}
        name="thursdayOpen"
        value={thursdayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isThursdayClosed}
        name="thursdayClose"
        value={thursdayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isThursdayClosed}
        name="isThursdayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
    <text>Sexta</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isFridayClosed}
        name="fridayOpen"
        value={fridayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isFridayClosed}
        name="fridayClose"
        value={fridayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isFridayClosed}
        name="isFridayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
    <text>Sábado</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isSaturdayClosed}
        name="saturdayOpen"
        value={saturdayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isSaturdayClosed}
        name="saturdayClose"
        value={saturdayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isSaturdayClosed}
        name="isSaturdayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
    <text>Domingo</text>
    <div>
      <label>Abre</label>
      <input
        className="form-control"
        type="time"
        disabled={isSundayClosed}
        name="sundayOpen"
        value={sundayOpen}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fecha</label>
      <input
        className="form-control"
        type="time"
        disabled={isSundayClosed}
        name="sundayClose"
        value={sundayClose}
        onChange={onChangeWorkingHours}
      />
    </div>
    <div>
      <label>Fechado? </label>
      <Switch
        color="primary"
        type="checkbox"
        checked={isSundayClosed}
        name="isSundayClosed"
        onChange={onChangeIsClosed}
      />
    </div>
  </div>
);
