import axios from "axios";
import removeSpecialCharacters from "../utils/removeSpecialCharacters";

export default async function getCoordinates(address) {
  let formatedAddress = address.toLowerCase().replace(/ /g, "+");
  formatedAddress = removeSpecialCharacters(formatedAddress);

  const { data } = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${formatedAddress}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
  );

  return data.results[0].geometry.location;
}
