import React from "react";
import { Button } from "@material-ui/core";

import "./CompanyForm.css";

export default ({
  companyName,
  zipCode,
  address,
  number,
  neighborhood,
  city,
  state,
  phone,
  whatsapp,
  website,
  facebook,
  instagram,
  onChangeForm,
  onChangeZipCode,
  onButtonClick,
  isMobile,
}) => (
  <div>
    <div style={{ marginBottom: "2em" }}>
      <label>Nome da Empresa</label>
      <input
        className="form-control"
        name="name"
        value={companyName}
        onChange={onChangeForm}
      />
    </div>
    <div className={isMobile ? "grid-mobile-div" : "grid-div"}>
      <div>
        <label>CEP</label>
        <input
          className="form-control"
          name="zipCode"
          value={zipCode}
          onChange={onChangeZipCode}
        />
      </div>
      <div>
        <label>Logradouro</label>
        <input
          className="form-control"
          name="address"
          value={address}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Nº</label>
        <input
          className="form-control"
          name="number"
          value={number}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Bairro</label>
        <input
          className="form-control"
          name="neighborhood"
          value={neighborhood}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Cidade</label>
        <input
          className="form-control"
          name="city"
          value={city}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Estado</label>
        <input
          className="form-control"
          name="state"
          value={state}
          onChange={onChangeForm}
        />
      </div>
      <Button
        onClick={onButtonClick}
        style={{
          color: "white",
          background: "#27ae60",
          fontFamily: "Poppins",
          fontWeight: "bold",
        }}
      >
        Marcar no Mapa
      </Button>
    </div>
    <div className={isMobile ? "grid-mobile-div" : "grid-div"}>
      <div>
        <label>Telefone</label>
        <input
          className="form-control"
          name="phone"
          value={phone}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Whatsapp</label>
        <input
          className="form-control"
          name="whatsapp"
          value={whatsapp}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Site</label>
        <input
          className="form-control"
          name="website"
          value={website}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Facebook</label>
        <input
          className="form-control"
          name="facebook"
          value={facebook}
          onChange={onChangeForm}
        />
      </div>
      <div>
        <label>Instagram</label>
        <input
          className="form-control"
          name="instagram"
          value={instagram}
          onChange={onChangeForm}
        />
      </div>
    </div>
  </div>
);
