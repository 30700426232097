import React from "react";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";

const mapStyles = {
  width: "100%",
  height: "100%",
};

export default ({ latitude, longitude, onMapClicked, width, height }) => (
  <div style={{ width: width, height: height }}>
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        onClick={onMapClicked}
        center={{
          lat: latitude,
          lng: longitude,
        }}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude,
          }}
        />
      </GoogleMap>
    </LoadScript>
  </div>
);
