import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Card,
  CardContent,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import "./App.css";

import MapView from "./components/MapView";
import CompanyForm from "./components/CompanyForm";
import WorkingHoursForm from "./components/WorkingHoursForm";

import getCoordinates from "./services/getCoordinates";
import sendEmail from "./services/sendEmail";

function App() {
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarErrorOpen, setSnackBarErrorOpen] = useState(false);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [companyForm, setCompanyForm] = useState({
    name: "",
    zipCode: "",
    address: "",
    number: "",
    neighborhood: "",
    city: "",
    state: "",
    phone: "",
    whatsapp: "",
    website: "",
    facebook: "",
    instagram: "",
  });
  const [workingHoursForm, setWorkingHoursForm] = useState({
    mondayOpen: "",
    mondayClose: "",
    tuesdayOpen: "",
    tuesdayClose: "",
    wednesdayOpen: "",
    wednesdayClose: "",
    thursdayOpen: "",
    thursdayClose: "",
    fridayOpen: "",
    fridayClose: "",
    saturdayOpen: "",
    saturdayClose: "",
    sundayOpen: "",
    sundayClose: "",
  });

  const [isClosedForm, setIsClosedForm] = useState({
    isMondayClosed: false,
    isTuesdayClosed: false,
    isWednesdayClosed: false,
    isThursdayClosed: false,
    isFridayClosed: false,
    isSaturdayClosed: false,
    isSundayClosed: false,
  });

  const onSendClicked = async () => {
    setLoading(true);
    const workingHoursData = {
      segunda: isClosedForm.isMondayClosed
        ? "Fechado"
        : `${workingHoursForm.mondayOpen} - ${workingHoursForm.mondayClose}`,
      terca: isClosedForm.isTuesdayClosed
        ? "Fechado"
        : `${workingHoursForm.tuesdayOpen} - ${workingHoursForm.tuesdayClose}`,
      quarta: isClosedForm.isWednesdayClosed
        ? "Fechado"
        : `${workingHoursForm.wednesdayOpen} - ${workingHoursForm.wednesdayClose}`,
      quinta: isClosedForm.isThursdayClosed
        ? "Fechado"
        : `${workingHoursForm.thursdayOpen} - ${workingHoursForm.thursdayClose}`,
      sexta: isClosedForm.isFridayClosed
        ? "Fechado"
        : `${workingHoursForm.fridayOpen} - ${workingHoursForm.fridayClose}`,
      sabado: isClosedForm.isSaturdayClosed
        ? "Fechado"
        : `${workingHoursForm.saturdayOpen} - ${workingHoursForm.saturdayClose}`,
      domingo: isClosedForm.isSundayClosed
        ? "Fechado"
        : `${workingHoursForm.sundayOpen} - ${workingHoursForm.sundayClose}`,
    };

    try {
      await sendEmail(
        companyForm,
        coords,
        workingHoursData,
        "sac@hublocal.com.br"
      );
  
      setSnackBarOpen(true);
      handleResetForm();
    } catch (error) {
      setSnackBarErrorOpen(true);
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleResetForm = () => {
    setCompanyForm({
      name: "",
      zipCode: "",
      address: "",
      number: "",
      neighborhood: "",
      city: "",
      state: "",
      phone: "",
      whatsapp: "",
      website: "",
      facebook: "",
      instagram: "",
    });

    setWorkingHoursForm({
      mondayOpen: "",
      mondayClose: "",
      tuesdayOpen: "",
      tuesdayClose: "",
      wednesdayOpen: "",
      wednesdayClose: "",
      thursdayOpen: "",
      thursdayClose: "",
      fridayOpen: "",
      fridayClose: "",
      saturdayOpen: "",
      saturdayClose: "",
      sundayOpen: "",
      sundayClose: "",
    });

    setIsClosedForm({
      isMondayClosed: false,
      isTuesdayClosed: false,
      isWednesdayClosed: false,
      isThursdayClosed: false,
      isFridayClosed: false,
      isSaturdayClosed: false,
      isSundayClosed: false,
    });
  }

  const handleChangeCompanyForm = ({ target: { name, value } }) => {
    setCompanyForm({ ...companyForm, [name]: value });
  };

  const handleChangeWorkingHoursForm = ({ target: { name, value } }) => {
    setWorkingHoursForm({ ...workingHoursForm, [name]: value });
  };

  const handleChangeIsCloseForm = ({ target: { name } }) => {
    setIsClosedForm({ ...isClosedForm, [name]: !isClosedForm[name] });
  };

  const handleButtonClick = async () => {
    const { lat, lng } = await getCoordinates(
      `${companyForm.address}, ${companyForm.number}`
    );
    setCoords({ lat, lng });
  };

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };

  const handleCloseSnackBarError = () => {
    setSnackBarErrorOpen(false);
  }

  const handleChangeZipCode = async ({ target: { value } }) => {
    setCompanyForm({ ...companyForm, zipCode: value });
    if (value.toString().length > 7) {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setCompanyForm({
        ...companyForm,
        address: data.logradouro,
        neighborhood: data.bairro,
        city: data.localidade,
        state: data.uf,
        zipCode: value,
      });
    }
  };

  const onMapClicked = ({ latLng: { lat, lng } }) => {
    setCoords({ lat: lat(), lng: lng() });
  };

  const renderButton = () => {
    if (loading) return <CircularProgress style={{ color: "#27ae60" }} />;
    return (
      <Button
        onClick={onSendClicked}
        style={{
          color: "white",
          background: "#27ae60",
          fontFamily: "Poppins",
          fontWeight: "bold",
          width: "20em",
        }}
      >
        Enviar
      </Button>
    );
  };

  return (
    <div style={{ textAlign: "center", margin: "1em" }}>
      <div className={isMobile ? "App-Mobile" : "App"}>
        <Card>
          <CardContent>
            <div className="title">
              <text>Informações do Local</text>
            </div>
            <CompanyForm
              companyName={companyForm.name}
              address={companyForm.address}
              city={companyForm.city}
              state={companyForm.state}
              facebook={companyForm.facebook}
              instagram={companyForm.instagram}
              neighborhood={companyForm.neighborhood}
              number={companyForm.number}
              phone={companyForm.phone}
              website={companyForm.website}
              whatsapp={companyForm.whatsapp}
              zipCode={companyForm.zipCode}
              onChangeForm={handleChangeCompanyForm}
              onChangeZipCode={handleChangeZipCode}
              onButtonClick={handleButtonClick}
              isMobile={isMobile}
            />
          </CardContent>
        </Card>
        <Card style={{ margin: "1em" }}>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MapView
              latitude={coords.lat}
              longitude={coords.lng}
              onMapClicked={onMapClicked}
              width={isMobile ? "20em" : "15em"}
              height={isMobile ? "20em" : "20em"}
            />
          </CardContent>
        </Card>
      </div>
      <Card style={{ marginTop: "1em" }}>
        <CardContent>
          <div className="title">
            <text>Horários de Funcionamento</text>
          </div>
          <WorkingHoursForm
            fridayClose={workingHoursForm.fridayClose}
            fridayOpen={workingHoursForm.fridayOpen}
            isFridayClosed={isClosedForm.isFridayClosed}
            isMondayClosed={isClosedForm.isMondayClosed}
            isSaturdayClosed={isClosedForm.isSaturdayClosed}
            isSundayClosed={isClosedForm.isSundayClosed}
            isThursdayClosed={isClosedForm.isThursdayClosed}
            isTuesdayClosed={isClosedForm.isTuesdayClosed}
            isWednesdayClosed={isClosedForm.isWednesdayClosed}
            mondayClose={workingHoursForm.mondayClose}
            mondayOpen={workingHoursForm.mondayOpen}
            onChangeIsClosed={handleChangeIsCloseForm}
            onChangeWorkingHours={handleChangeWorkingHoursForm}
            saturdayClose={workingHoursForm.saturdayClose}
            saturdayOpen={workingHoursForm.saturdayOpen}
            sundayClose={workingHoursForm.sundayClose}
            sundayOpen={workingHoursForm.sundayOpen}
            thursdayClose={workingHoursForm.thursdayClose}
            thursdayOpen={workingHoursForm.thursdayOpen}
            tuesdayClose={workingHoursForm.tuesdayClose}
            tuesdayOpen={workingHoursForm.tuesdayOpen}
            wednesdayClose={workingHoursForm.wednesdayClose}
            wednesdayOpen={workingHoursForm.wednesdayOpen}
            isMobile={isMobile}
          />
        </CardContent>
      </Card>
      <div
        style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}
      >
        {renderButton()}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContent
          message="Dados Enviados com sucesso"
          style={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            backgroundColor: "#27AE60",
            color: "white",
          }}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBarErrorOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackBarError}
      >
        <SnackbarContent
          message="Erro ao enviar e-mail"
          style={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            backgroundColor: "#D0312D",
            color: "white",
          }}
        />
      </Snackbar>
    </div>
  );
}

export default App;
