import axios from "axios";

export default async function sendEmail(
  companyData,
  coordinates,
  workingHoursData,
  sendTo
) {
  const data = await axios.post(
    `${process.env.REACT_APP_LOCATION_API_URL}/send-mail`,
    {
      company_data: companyData,
      coordinates,
      working_hours_data: workingHoursData,
      send_to: sendTo,
    }
  );
  return data;
}
